<template>
  <v-container fluid class="mt-10 mb-16">
    <v-row class="mt-10" justify="center">
      <v-col align="center" cols="8">
        <h2 class="text-center">Has GraceWay Radio blessed you in any way?</h2>
        <p align="center" class="mt-4" style="font-weight: bold; text-indent: 20px">
          If so, WE'D LOVE TO HEAR FROM YOU! Please filled out the form below and share your
          experience.
        </p>
      </v-col>
    </v-row>
    <v-row class="mt-10">
      <v-col align="center">
        <v-form style="width: 700px; max-width: 90%">
          <v-text-field
            v-model="first_name"
            label="First Name"
            :error-messages="firstNameErrors"
            :counter="40"
            required
            @input="$v.first_name.$touch()"
            @blur="$v.first_name.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="last_name"
            label="Last Name"
            required
            :counter="40"
            :error-messages="lastNameErrors"
            @input="$v.last_name.$touch()"
            @blur="$v.last_name.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="email"
            label="E-mail"
            required
            :error-messages="emailErrors"
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
          ></v-text-field>
          <v-select
            v-model="select"
            :items="countryList"
            label="Country"
            required
            :error-messages="selectErrors"
            @input="$v.message.$touch()"
            @blur="$v.message.$touch()"
          ></v-select>
          <v-text-field
            v-model="state"
            label="State"
            required
            :counter="40"
            :error-messages="stateErrors"
            @input="$v.state.$touch()"
            @blur="$v.state.$touch()"
          ></v-text-field>
          <v-textarea
            v-model="message"
            :counter="260"
            label="Message"
            required
            :error-messages="messageErrors"
          ></v-textarea>
          <v-row justify="center" class="mt-8">
            <v-col align="center">
              <v-btn color="red lighten-2 white--text" class="mr-4" @click="clear"
                >Reset Form</v-btn
              >
              <v-btn color="blue lighten-1 white--text" class="mr-4" @click="submit">Submit</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-overlay :value="overlay" :z-index="zIndex">
      <v-snackbar
        v-model="overlay"
        timeout="3000"
        absolute
        centered
        color="deep-purple accent-4"
        elevation="24"
        >{{ createSuccessMessage }}</v-snackbar
      >
    </v-overlay>
  </v-container>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  validations: {
    first_name: { required, maxLength: maxLength(40) },
    last_name: { required, maxLength: maxLength(40) },
    email: { required, email },
    select: { required },
    state: { required, maxLength: maxLength(40) },
    message: { required, maxLength: maxLength(260) },
  },
  name: "CreateTestimony",
  data: () => ({
    overlay: false,
    zIndex: 0,
    successfulSubmission: false,
    createSuccessMessage: "",
    first_name: "",
    last_name: "",
    email: "",
    message: "",
    state: "",
    select: null,
    countryList: [
      "Afghanistan",
      "Albania",
      "Algeria",
      "American Samoa",
      "Andorra",
      "Angola",
      "Anguilla",
      "Antarctica",
      "Antigua and Barbuda",
      "Argentina",
      "Armenia",
      "Aruba",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas (the)",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bermuda",
      "Bhutan",
      "Bolivia (Plurinational State of)",
      "Bonaire, Sint Eustatius and Saba",
      "Bosnia and Herzegovina",
      "Botswana",
      "Bouvet Island",
      "Brazil",
      "British Indian Ocean Territory (the)",
      "Brunei Darussalam",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cabo Verde",
      "Cambodia",
      "Cameroon",
      "Canada",
      "Cayman Islands (the)",
      "Central African Republic (the)",
      "Chad",
      "Chile",
      "China",
      "Christmas Island",
      "Cocos (Keeling) Islands (the)",
      "Colombia",
      "Comoros (the)",
      "Congo (the Democratic Republic of the)",
      "Congo (the)",
      "Cook Islands (the)",
      "Costa Rica",
      "Croatia",
      "Cuba",
      "Curaçao",
      "Cyprus",
      "Czechia",
      "Côte d'Ivoire",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic (the)",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Estonia",
      "Eswatini",
      "Ethiopia",
      "Falkland Islands (the) [Malvinas]",
      "Faroe Islands (the)",
      "Fiji",
      "Finland",
      "France",
      "French Guiana",
      "French Polynesia",
      "French Southern Territories (the)",
      "Gabon",
      "Gambia (the)",
      "Georgia",
      "Germany",
      "Ghana",
      "Gibraltar",
      "Greece",
      "Greenland",
      "Grenada",
      "Guadeloupe",
      "Guam",
      "Guatemala",
      "Guernsey",
      "Guinea",
      "Guinea-Bissau",
      "Guyana",
      "Haiti",
      "Heard Island and McDonald Islands",
      "Holy See (the)",
      "Honduras",
      "Hong Kong",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran (Islamic Republic of)",
      "Iraq",
      "Ireland",
      "Isle of Man",
      "Israel",
      "Italy",
      "Jamaica",
      "Japan",
      "Jersey",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kiribati",
      "Korea (the Democratic People's Republic of)",
      "Korea (the Republic of)",
      "Kuwait",
      "Kyrgyzstan",
      "Lao People's Democratic Republic (the)",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macao",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Marshall Islands (the)",
      "Martinique",
      "Mauritania",
      "Mauritius",
      "Mayotte",
      "Mexico",
      "Micronesia (Federated States of)",
      "Moldova (the Republic of)",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Montserrat",
      "Morocco",
      "Mozambique",
      "Myanmar",
      "Namibia",
      "Nauru",
      "Nepal",
      "Netherlands (the)",
      "New Caledonia",
      "New Zealand",
      "Nicaragua",
      "Niger (the)",
      "Nigeria",
      "Niue",
      "Norfolk Island",
      "Northern Mariana Islands (the)",
      "Norway",
      "Oman",
      "Pakistan",
      "Palau",
      "Palestine, State of",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines (the)",
      "Pitcairn",
      "Poland",
      "Portugal",
      "Puerto Rico",
      "Qatar",
      "Republic of North Macedonia",
      "Romania",
      "Russian Federation (the)",
      "Rwanda",
      "Réunion",
      "Saint Barthélemy",
      "Saint Helena, Ascension and Tristan da Cunha",
      "Saint Kitts and Nevis",
      "Saint Lucia",
      "Saint Martin (French part)",
      "Saint Pierre and Miquelon",
      "Saint Vincent and the Grenadines",
      "Samoa",
      "San Marino",
      "Sao Tome and Principe",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Sint Maarten (Dutch part)",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "South Georgia and the South Sandwich Islands",
      "South Sudan",
      "Spain",
      "Sri Lanka",
      "Sudan (the)",
      "Suriname",
      "Svalbard and Jan Mayen",
      "Sweden",
      "Switzerland",
      "Syrian Arab Republic",
      "Taiwan",
      "Tajikistan",
      "Tanzania, United Republic of",
      "Thailand",
      "Timor-Leste",
      "Togo",
      "Tokelau",
      "Tonga",
      "Trinidad and Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Turks and Caicos Islands (the)",
      "Tuvalu",
      "Uganda",
      "Ukraine",
      "United Arab Emirates (the)",
      "United Kingdom of Great Britain and Northern Ireland (the)",
      "United States Minor Outlying Islands (the)",
      "United States of America (the)",
      "Uruguay",
      "Uzbekistan",
      "Vanuatu",
      "Venezuela (Bolivarian Republic of)",
      "Viet Nam",
      "Virgin Islands (British)",
      "Virgin Islands (U.S.)",
      "Wallis and Futuna",
      "Western Sahara",
      "Yemen",
      "Zambia",
      "Zimbabwe",
      "Åland Islands",
    ],
  }),
  methods: {
    clear() {
      this.$v.$reset();
      this.first_name = "";
      this.last_name = "";
      this.email = "";
      this.select = "";
      this.state = null;
      this.message = "";
      this.successfulSubmission = false;
    },
    submit() {
      // const url = "http://127.0.0.1:8000/create_testimony/";
      const url = "https://gwrapi.herokuapp.com/create_testimony/";
      const entry = {
        first_name: this.first_name,
        last_name: this.last_name,
        email_address: this.email,
        message: this.message,
        state: this.state,
        country: this.select,
      };
      axios.post(url, entry).then(
        (res) => {
          if (res.statusText === "Created" && res.status === 201) {
            this.overlay = true;
            this.successfulSubmission = true;
            this.zIndex = 1;
            this.createSuccessMessage = "Submission Successful!";
            this.clear();
          }
        },
        (error) => {
          const responseError = error.response.data.non_field_errors[0];
          this.overlay = true;
          this.zIndex = 1;
          this.createSuccessMessage = responseError
            ? responseError
            : "An Error Occurred, Please Try Again";
          this.clear();
        }
      );
    },
  },
  computed: {
    selectErrors() {
      const errors = [];
      if (!this.$v.select.$dirty) return errors;
      !this.$v.select.required && errors.push("Item is required");
      return errors;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.first_name.$dirty) return errors;
      !this.$v.first_name.maxLength && errors.push("First Name must be at most 40 characters long");
      !this.$v.first_name.required && errors.push("First Name is required.");
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.last_name.$dirty) return errors;
      !this.$v.last_name.maxLength && errors.push("Last Name must be at most 40 characters long");
      !this.$v.last_name.required && errors.push("Last Name is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    messageErrors() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;
      !this.$v.message.maxLength && errors.push("Message must be at most 260 characters long");
      !this.$v.message.required && errors.push("Message is required.");
      return errors;
    },
    stateErrors() {
      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      !this.$v.state.maxLength && errors.push("State must be at most 40 characters long");
      !this.$v.state.required && errors.push("State is required.");
      return errors;
    },
  },
};
</script>
