<template>
  <div>
    <banner title="Testimonies" />
    <div v-if="!removeTestimonies">
      <testimony :testimonies="testimonies" class="mt-5" />
      <v-row>
        <v-col align="end">
          <v-btn :disabled="turnOffButton(previousPage)" @click="getNextPrevious(previousPage)"
            color="blue lighten-1 white--text">Previous</v-btn>
        </v-col>
        <v-divider vertical inset></v-divider>
        <v-col align="start">
          <v-btn :disabled="turnOffButton(nextPage)" @click="getNextPrevious(nextPage)"
            color="blue lighten-1 white--text">Next</v-btn>
        </v-col>
      </v-row>
    </div>
    <create-testimony ref="testimony-form" />
  </div>
</template>

<script>
import Banner from "../components/Banner.vue";
import CreateTestimony from "../components/testimony/CreateTestimony.vue";
import Testimony from "../components/testimony/Testimony.vue";

export default {
  name: "Testimonies",
  components: { Testimony, CreateTestimony, Banner },
  data: () => ({
    removeTestimonies: false,
  }),
  watch: {
    whichItems(item) {
      testimonies(item);
    }
  },
  metaInfo() {
    return {
      title: "Testimonies",

      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "GraceWay Radio is dedicated to spiritual growth in our listeners. Please leave us a testimony to tell us how our station has blessed you!"
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "GraceWay Radio is dedicated to spiritual growth in our listeners. Please leave us a testimony to tell us how our station has blessed you!"
        },
        { vmid: "og:title", property: "og:title", content: "GraceWay Testimonies" },
        { vmid: "robots", name: "robots", content: "index,follow" },
        {
          vmid: "og:url",
          property: "og:url",
          content: "https://gracewayradio.com/testimonies"
        }
      ]
    };
  },
  methods: {
    turnOffButton(param) {
      return param === null;
    },
    getNextPrevious(param) {
      this.$store.dispatch("testimonyObjects/getTestimonyPage", param);
    },
    scrolltoForm() {
      let element = this.$refs["testimony-form"].$el;
      this.$vuetify.goTo(element);
    },
    getTestimonies() {
      this.$nextTick(() => {
        if (!this.testimonies) {
          this.$store.dispatch("testimonyObjects/getTestimonies");
        }
      });
    }
  },
  computed: {
    testimonies() {
      return this.$store.state.testimonyObjects?.testimonies?.results;
    },
    nextPage() {
      return this.$store.state.testimonyObjects?.testimonies?.next;
    },
    previousPage() {
      return this.$store.state.testimonyObjects?.testimonies?.previous;
    }
  },
  mounted() {
    if (!this.$route?.params?.query) {
      this.getTestimonies();
    }
    if (this.$route?.params?.query && this.$route?.params?.query === "testimony-form") {
      this.scrolltoForm();
      setTimeout(() => {
        this.getTestimonies();
      }, 2000)
    } else if (this.$route?.params?.query && this.$route?.params?.query === "campaign") {
      this.removeTestimonies = true;
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
