<template>
  <v-container fluid>
    <v-sheet class="mx-auto py-10" rounded max-width="700">
      <v-row v-for="item in testimonies" :key="item.id" class="mb-16">
        <v-col align="center">
          <v-row>
            <v-col>
              <v-img
                eager
                style="border-radius: 10px"
                height="200"
                position="center center"
                :src="item.image"
                :alt="item.first_name"
              ></v-img>
            </v-col>
            <v-col>
              <v-card-title class="message">{{ wrapQuotes(item.message) }}</v-card-title>
              <v-card-subtitle>{{ item.first_name }} | {{ item.state }}, {{ item.country }}</v-card-subtitle>
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: "Testimony",
  props: {
    testimonies: {
      type: Array,
    },
  },
  data: () => ({}),
  methods: {
    wrapQuotes(message) {
      return '"' + message + '"';
    },
  },
};
</script>

<style scoped>
/* @import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap"); */
.message {
  font-family: "Source Sans Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  margin-bottom: 10px;
  word-break: break-word;
}
</style>
